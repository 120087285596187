import React, { createRef, useEffect } from "react";
import Section from "../../../containers/Section";
import Badge from "../../Badge";
import Paragraph from "../../Paragraph";
import ProductCard from "../../ProductCard";
import Button, { ButtonVariant } from "../../Button";
import PrismLogo from "../assets/logo.svg";
import prismAnimation from "../../../animations/PrismPage/prism-waves.json";
import Header, { HeaderVariant } from "../../Header";
import { IconType } from "../../../components/Icon";
import { nav } from "../../../utils/navigation";
import { useStaticQuery, graphql } from "gatsby";
import { runAnimation } from "../../../utils/animations";
import { trackView, appendTrackingParams } from "../../../utils/analytics";
import { Name, Category } from "../../../utils/analytics/constants";

const Herobanner: React.FC = props => {
  const data = useStaticQuery(graphql`
    {
      sneakers: file(relativePath: { eq: "prism-page/sneakers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      glasses: file(relativePath: { eq: "prism-page/glasses.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const animationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim =
      prismAnimation &&
      runAnimation(animationContainer.current, prismAnimation);
    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <Section className="pt-14 md:pt-32 mx-auto flex justify-center flex-col items-center">
      <Badge
        className="mb-20"
        title="Market Awareness Software"
        iconType={IconType.prism}
      />
      <div className="grid w-full items-center grid-cols-4 lg:grid-cols-5">
        {/* Space For slide in */}
        <div className="col-span-2 pr-6 order-2 lg:order-1 mt-22 relative top-8 w-[130%] -translate-x-[20%] sm:translate-x-0 sm:w-full sm:top-0 lg:mt-0 lg:col-span-1">
          <div
            data-sal="slide-left"
            data-sal-duration="400"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <ProductCard
              title="$32M"
              subTitle="Est. Sales"
              alignment="left"
              image={data?.sneakers?.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>
        <div className="col-span-4 order-1 lg:col-span-3 lg:order-2">
          <Header
            variant={HeaderVariant.h2}
            color="purple"
            className="text-white text-center text-4xl lg:text-6xl flex flex-col items-center"
          >
            {[
              "Integrated market and",
              "competitive intelligence.",
              "Only in Perpetua."
            ].map((text, i) => {
              return (
                <span
                  key={i}
                  className={
                    i === 2
                      ? "bg-gradient-to-l from-indigo-1 to-purple-1 text-transparent bg-clip-text"
                      : "text-white"
                  }
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-delay={`${i + 3}00`}
                  data-sal-easing="ease"
                >
                  {text}
                </span>
              );
            })}
          </Header>
        </div>
        {/* Space for slide in */}
        <div className="col-span-2 pl-6 relative mt-22 order-3 w-[130%] sm:translate-x-0 sm:w-full lg:mt-0 lg:col-span-1">
          <div
            data-sal="slide-right"
            data-sal-duration="400"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <ProductCard
              title="$32M"
              subTitle="Est. Sales"
              alignment="right"
              image={data?.glasses?.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl flex flex-col justify-center items-center">
        <Paragraph className="mt-32 text-center text-white lg:px-12 lg:font-semibold lg:text-4xl">
          Turn insights into sales with Prism by Perpetua — where market and
          competitive intelligence collide with leading advertising optimization
          tools to fuel your growth.
        </Paragraph>
      </div>
      <div className="mx-6 my-12 flex justify-center items-center relative">
        <div
          ref={animationContainer}
          className="absolute z-0 top-1/2 left-1/2 w-screen -translate-x-1/2 -translate-y-1/2"
        ></div>
        <PrismLogo className="relative z-10 w-1/2 lg:w-full" />
      </div>
      <Button
        onClick={() => {
          // Run track call then go somewhere
          trackView(Category.Prism + " LP", {
            category: Category.Prism,
            label: Name.Cta + " (Hero banner)"
          });
          nav(
            appendTrackingParams(
              "https://perpetuasurveys.typeform.com/to/OsZVcZNg/?loc=hero-banner"
            )
          );
        }}
        className="mx-auto"
        variant={ButtonVariant.primary}
      >
        Get Early Access
      </Button>
    </Section>
  );
};

export default Herobanner;
