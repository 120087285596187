import React from "react";
import Paragraph from "../Paragraph";

interface GradientBoxProps {
  style?: any;
  title?: string;
}

const GradientBox: React.FC<GradientBoxProps> = props => {
  const { title, style } = props;

  return (
    <div
      style={style}
      className="px-3 w-full min-h-[128px] py-4 blur-none mx-auto z-10 text-white text-base md:text-lg font-semibold md:font-bold h-full flex align-center !rounded-lg items-center uppercase lg:px-8"
    >
      {title}
    </div>
  );
};

export default GradientBox;
