import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Paragraph from "../Paragraph";
import cn from "classnames";
import GradientTitle from "../GradientTitle";
import { HeaderVariant } from "../Header";
import BlurContainer from "../../containers/BlurContainer";
import { useMedia } from "use-media";
import BigArrow from "../../images/big-arrow.svg";

interface SplitFeatureSelectorProps {
  options: any;
}

const SplitFeatureSelector: React.FC<SplitFeatureSelectorProps> = props => {
  const { options } = props;
  const [selectedItem, setSelectedItem] = useState<number>(0);

  const isDesktop = useMedia({ minWidth: "1024px" });
  const sharedArrows = "w-26 min-h-full ml-1 mb-1 text-indigo-1 h-full";

  return (
    <div className="grid grid-cols-2 md:mb-52 lg:mb-0 py-4 z-0">
      <div
        className={cn(
          "col-span-2 flex relative transition-all duration-1000 ease-in-out md:min-w-[100vw] lg:flex-col lg:min-w-full lg:min-h-[45vh] lg:col-span-1",
          selectedItem === options.length - 1 && "justify-start",
          selectedItem === 0 && "justify-end"
        )}
      >
        {options?.map((option, i) => (
          <div
            key={i}
            className={cn(
              "min-h-[220px] w-full md:mr-20 text-center md:text-left lg:mb-22 last:mb-0 lg:min-w-[auto] lg:min-h-[auto] lg:mr-0",
              selectedItem !== i && "hidden lg:block lg:opacity-40"
            )}
          >
            <div
              className="cursor-pointer"
              onClick={() => {
                setSelectedItem(i);
              }}
            >
              <GradientTitle
                variant={HeaderVariant.h3}
                color="blue"
                gradientPosition={option.gradientPosition}
                title={option.gradientTitle}
                lineBreak={false}
                animation={false}
                className="mb-8 w-full"
              />
            </div>
            <Paragraph
              className={cn(
                "md:text-2xl w-full",
                selectedItem === i
                  ? "opacity-1"
                  : "opacity-0 absolute h-0 transition duration-300 ease-in"
              )}
            >
              {option.description}
            </Paragraph>
          </div>
        ))}
      </div>
      <div className="my-12 col-span-2 lg:hidden">
        <div className="w-3/4 min-w-[300px] mx-auto flex items-center justify-center cursor-pointer">
          <BigArrow
            onClick={() =>
              selectedItem > 0 && setSelectedItem(selectedItem - 1)
            }
            className={cn(
              sharedArrows,
              "rotate-180",
              selectedItem === 0 && "cursor-not-allowed opacity-20"
            )}
          />
          {options?.map((option, i) => {
            return (
              <button
                key={i}
                className={cn(
                  "rounded-full text-xl text-white border px-6 py-4 flex items-center justify-center text-center mx-4",
                  selectedItem === i
                    ? "bg-indigo-1 border-indigo-1"
                    : "border-indigo-1 bg-transparent text-indigo-1"
                )}
                onClick={() => setSelectedItem(i)}
              >
                {i + 1}
              </button>
            );
          })}
          <BigArrow
            onClick={() =>
              selectedItem < options.length - 1 &&
              setSelectedItem(selectedItem + 1)
            }
            className={cn(
              sharedArrows,
              selectedItem === options.length - 1 &&
              "cursor-not-allowed opacity-20"
            )}
          />
        </div>
      </div>
      <div className="col-span-2 flex flex-col last:mb-0 relative lg:col-span-1">
        <BlurContainer className="absolute top-0 left-0 mx-auto w-[90vw] h-[300px] sm:h-[400px] md:h-[525px] lg:-translate-y-1/2 lg:translate-x-1/4 z-0 lg:top-1/3 lg:h-[300px] lg:w-[50vw] xl:top-1/2 xl:h-[400px]"></BlurContainer>
        <div
          className="flex absolute transition duration-1000 ease-in-out min-h-[400px] lg:min-h-[auto] lg:block"
          style={{
            transform: isDesktop
              ? `translateY(-${((selectedItem + 1) / options.length) * 100}%)`
              : `translateX(-${(selectedItem / options.length) * 100}%)`
          }}
        >
          {options.map((option, i) => (
            <div
              key={i}
              className={cn(
                "w-[90vw] h-full transition duration-700 ease-in flex flex-col z-0 lg:translate-y-full lg:translate-x-1/4 lg:w-[50vw]",
                selectedItem === i ? "opacity-100" : "opacity-0 -z-100"
              )}
            >
              <GatsbyImage
                image={option?.image?.childImageSharp?.gatsbyImageData}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SplitFeatureSelector;
