import React, { createRef, useEffect } from "react";
import Header, { HeaderVariant } from "../../Header";
import PowerVectors from "../../../images/prism-page/power-vectors.svg";
import TabletPowerVectors from "../../../images/prism-page/tablet-power-vectors.svg";
import MobilePowerVectors from "../../../images/prism-page/mobile-power-vectors.svg";
import useMedia from "use-media";
import cn from "classnames";
import Section from "../../../containers/Section";
import { graphql, useStaticQuery } from "gatsby";
import ProductCard from "../../ProductCard";
import prismAnimation from "../../../animations/PrismPage/prism-waves.json";
import { runAnimation } from "../../../utils/animations";
import Icon, { IconType } from "../../Icon";
import { animated, config, useSprings } from "react-spring";

interface PowerProps {
  gradientTitle: any;
  gradientTitlePosition: number;
  description: string;
}

const Power: React.FC<PowerProps> = props => {
  const { gradientTitle, gradientTitlePosition, description } = props;

  const isDesktop = useMedia({ minWidth: "1300px" });
  const isMobile = useMedia({ maxWidth: "767px" });

  const data = useStaticQuery(graphql`
    {
      toys: file(relativePath: { eq: "prism-page/toys.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
      sneakers: file(relativePath: { eq: "prism-page/sneakers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
      mobileSneakers: file(relativePath: { eq: "prism-page/sneakers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 70, height: 80)
        }
      }
      glasses: file(relativePath: { eq: "prism-page/glasses.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
      mobileGlasses: file(relativePath: { eq: "prism-page/glasses.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 70, height: 80)
        }
      }
      yoga: file(relativePath: { eq: "prism-page/yoga-roller.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
    }
  `);

  const products = [
    {
      title: "Est. Sales",
      price: "$1.3M",
      image: isMobile
        ? data?.mobileToys?.childImageSharp.gatsbyImageData
        : data?.toys?.childImageSharp.gatsbyImageData
    },
    {
      title: "Est. Units Sold",
      price: "25.6K",
      image: isMobile
        ? data?.mobileSneakers?.childImageSharp.gatsbyImageData
        : data?.sneakers?.childImageSharp.gatsbyImageData
    },
    {
      title: "Avg. Price",
      price: "$326",
      image: data?.glasses?.childImageSharp.gatsbyImageData
    },
    {
      title: "Avg. Ratings",
      price: "4.0",
      image: data?.yoga?.childImageSharp.gatsbyImageData
    }
  ];

  const mobileProducts = [
    {
      title: "Est. Units Sold",
      price: "25.6K",
      image: isMobile
        ? data?.mobileSneakers?.childImageSharp.gatsbyImageData
        : data?.sneakers?.childImageSharp.gatsbyImageData
    },
    {
      title: "Avg. Price",
      price: "$326",
      image: isMobile
        ? data?.mobileGlasses?.childImageSharp.gatsbyImageData
        : data?.glasses?.childImageSharp.gatsbyImageData
    }
  ];

  const productsArray = isMobile ? mobileProducts : products;

  const goals = ["Goals", "Goals", "Goals", "Goals", "Goals", "Goals", "Goals"];

  const [goalsSprings, setGoals] = useSprings(goals && goals?.length, index => {
    return {
      from: { transform: `translate(0px,0px)` },
      to: async next => {
        let count = 1;
        while (count <= 6) {
          await next({
            transform: `translate(${count * -137}px, 0px)`
          });
          count = count + 1;
        }
      },
      loop: true,
      config: {
        ...config.gentle,
        duration: 6000
      }
    };
  });

  const goalsAnimation = (
    <div className="w-screen flex absolute left-0 flex-rows items-center space-x-3 lg:items-end bottom-[140px] sm:bottom-[200px]">
      {goalsSprings?.map((props, idx) => {
        const gl = goals[idx];
        return (
          <animated.div
            key={idx}
            className="w-full flex justify-center mx-0 items-center"
            style={props}
          >
            <span className="flex stroketext-white text-4xl md:text-6xl uppercase items-center tracking-[0.08em]">
              <div>{gl}</div>

              <Icon
                className="ml-7 mr-4 sm:w-14 sm:h-14"
                iconType={IconType.rocket}
              />
            </span>
          </animated.div>
        );
      })}
    </div>
  );

  const animationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim =
      prismAnimation &&
      runAnimation(animationContainer.current, prismAnimation);
    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <Section className="mx-auto overflow-hidden px-0 w-full flex justify-center flex-col items-center">
      <div className="relative text-center w-full">
        <div className="pb-8 justify-center">
          <Header
            variant={HeaderVariant.h2}
            color="purple"
            className="text-white text-center text-3xl sm:text-6xl flex flex-col items-center"
          >
            {gradientTitle.map((text, i) => {
              return (
                <span
                  key={i}
                  className={
                    i === 1
                      ? "bg-gradient-to-r bg-clip-text from-indigo-1 to-blue-1 text-transparent md:text-6xl text-white md:block"
                      : "text-white"
                  }
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  {text}
                </span>
              );
            })}
          </Header>
        </div>
        <p
          data-sal="slide-up"
          data-sal-duration="500"
          data-sal-delay="600"
          data-sal-easing="ease"
          className="md:text-3xl mx-auto font-semibold max-w-2xl text-white sm:mb-12"
        >
          {description && description}
        </p>
        <div className="sm:my-20 flex justify-center items-center relative">
          <div
            ref={animationContainer}
            className="absolute z-0 top-36 sm:top-44 left-1/2 w-screen -translate-x-1/2 -translate-y-1/2"
          ></div>
          <div className="sm:my-20 relative w-full">
            {isDesktop ? (
              <PowerVectors className="w-full z-20 relative" />
            ) : isMobile ? (
              <MobilePowerVectors className="w-full z-20 relative" />
            ) : (
              <TabletPowerVectors className="w-full z-20 relative" />
            )}

            <div
              className={cn(
                "flex z-30 flex-row flex-wrap w-full justify-center items-center text-center sm:text-left absolute left-[50%] sm:mt-13 -translate-x-1/2 -translate-y-1/2 top-[346px] sm:top-[286px] md:top-[596px] xl:top-[400px]",
                isDesktop && "!mt-0"
              )}
            >
              {productsArray &&
                productsArray.map((product, i) => {
                  return (
                    <ProductCard
                      key={i}
                      title={product.title}
                      price={product.price}
                      alignment="center"
                      section="power"
                      image={product.image}
                      className={cn(
                        "sm:mx-12 mx-6 sm:my-8 z-30 w-[35%] sm:w-3/12 ",
                        isDesktop && "!w-[15%] my-0"
                      )}
                    />
                  );
                })}
            </div>
          </div>
          {goalsAnimation}
        </div>
      </div>
    </Section>
  );
};

export default Power;
