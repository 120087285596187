import React from "react";
import cn from "classnames";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import SplitFeatureSelector from "../../SplitFeatureSelector";

interface AheadProps {
  title?: string[];
  options?: any;
}

const Ahead: React.FC<AheadProps> = props => {
  const { title, options } = props;

  return (
    <Section className="text-white py-40 lg:pt-52">
      <Header
        className="text-center relative z-10 !text-5xl md:!text-7xl bg-gradient-to-r from-red-1 via-indigo-1  to-purple-1 bg-clip-text"
        variant={HeaderVariant.h2}
      >
        {title &&
          title?.map((text, i) => (
            <span
              data-sal="slide-left"
              data-sal-duration="300"
              data-sal-delay={`${i + 2}00`}
              data-sal-easing="ease"
              key={text + i}
              className={cn(
                i > 0 ? "text-transparent bg-clip-text" : "text-white"
              )}
            >
              {text}
            </span>
          ))}
      </Header>
      <div className="w-full mt-10 md:mt-18 relative z-0">
        <SplitFeatureSelector options={options} />
      </div>
    </Section>
  );
};

export default Ahead;
