import React from "react";
interface BlurGradientProps {
  style?: any;
  className?: string;
}

const BlurContainer: React.FC<
  React.PropsWithChildren<BlurGradientProps>
> = props => {
  const {
    children,
    style = {
      background:
        "conic-gradient(from 180deg at 50% 50%, #FA0164 0deg, #42A5F5 125.47deg, #8241F3 226.72deg, #FDBA74 360deg)"
    },
    className
  } = props;

  return (
    <div className={`${className} relative`}>
      <div
        style={style}
        className="absolute blur-2xl top-0 left-0 right-0 bottom-0"
      ></div>
      {children}
    </div>
  );
};

export default BlurContainer;
