import React, { createRef, useEffect } from "react";
import Paragraph from "../../Paragraph";
import ProductCard from "../../ProductCard";
import { useStaticQuery, graphql } from "gatsby";
import Chart from "../../../images/prism-page/charts.svg";
import Section from "../../../containers/Section";
import productAnalyser from "../../../animations/PrismPage/prism-product-analyser.json";
import BlurContainer from "../../../containers/BlurContainer";
import { animated, config, useSprings } from "react-spring";
import { runAnimation } from "../../../utils/animations";
import cn from "classnames";
interface MarketExlporerProps {
  subTitle: string;
  mainTitle: any;
  description: string;
}

const MarketExlporer: React.FC<MarketExlporerProps> = props => {
  const { subTitle, mainTitle, description } = props;

  const gradientOverlayShared =
    "bottom-0 z-10 w-72 h-96 from-black hidden lg:block lg:absolute";

  const data = useStaticQuery(graphql`
    {
      toys: file(relativePath: { eq: "prism-page/toys.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
      sneakers: file(relativePath: { eq: "prism-page/sneakers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
      glasses: file(relativePath: { eq: "prism-page/glasses.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
      yoga: file(relativePath: { eq: "prism-page/yoga-roller.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
      Tshirt: file(relativePath: { eq: "prism-page/t-shirt.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
      mixer: file(relativePath: { eq: "prism-page/mixer.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 169, height: 169)
        }
      }
    }
  `);

  const products = [
    { title: "Toys", image: data?.toys?.childImageSharp.gatsbyImageData },
    { title: "Shoes", image: data?.sneakers?.childImageSharp.gatsbyImageData },
    {
      title: "Accessories",
      image: data?.glasses?.childImageSharp.gatsbyImageData
    },
    { title: "Lifestyle", image: data?.yoga?.childImageSharp.gatsbyImageData },
    { title: "Apparel", image: data?.Tshirt?.childImageSharp.gatsbyImageData },
    {
      title: "Appliances",
      image: data?.mixer?.childImageSharp.gatsbyImageData
    },
    { title: "Toys", image: data?.toys?.childImageSharp.gatsbyImageData },
    { title: "Shoes", image: data?.sneakers?.childImageSharp.gatsbyImageData },
    {
      title: "Accessories",
      image: data?.glasses?.childImageSharp.gatsbyImageData
    }
  ];

  const animationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim = runAnimation(
      animationContainer.current,
      productAnalyser,
      true
    );
    return () => {
      anim.destroy();
    };
  }, []);

  const [productsSprings, set] = useSprings(
    products && products?.length,
    index => {
      return {
        from: { transform: `translate(0px,0px)` },
        to: async next => {
          let count = 1;
          while (count <= 6) {
            await next({
              transform: `translate(${count * -137}px, 0px)`
            });
            count = count + 1;
          }
        },
        loop: true,
        config: {
          ...config.gentle,
          duration: 6000
        }
      };
    }
  );

  const productsAnimation = (
    <div className="flex absolute w-full flex-rows items-center space-x-3 overflow-x-hidden right-0 lg:items-end bottom-[-40px] sm:bottom-[75px]">
      {productsSprings?.map((props, idx) => {
        const product = products[idx];
        return (
          <animated.div
            key={idx}
            className="w-full flex justify-end"
            style={props}
          >
            <ProductCard
              key={idx}
              title={product.title}
              section="market-explorer"
              chartImage={<Chart />}
              image={product.image}
            />
          </animated.div>
        );
      })}
    </div>
  );

  return (
    <Section className="w-full py-20 sm:pt-52 mx-auto flex justify-center flex-col items-center relative">
      <p className="mb-8 text-indigo-2-light text-2xl">{subTitle}</p>
      <div className="flex flex-col lg:flex-row items-center lg:items-start py-6 sm:py-22 justify-center">
        {mainTitle &&
          mainTitle?.map((item, i) => {
            return (
              <p
                key={i}
                className={`${item.customClasses} text-4xl md:text-5xl xl:text-6xl font-semibold md:font-bold`}
              >
                {item?.text}
              </p>
            );
          })}
      </div>
      <Paragraph className="md:text-4xl my-12 mx-auto font-semibold max-w-3xl text-white text-center">
        {description && description}
      </Paragraph>
      <BlurContainer
        style={{
          background:
            "conic-gradient(from 180deg at 50% 50%, #FA0164 0deg, #42A5F5 125.47deg, #8241F3 226.72deg, #FDBA74 360deg)"
        }}
        className="flex my-18 z-20"
      >
        <div
          className="w-full max-w-screen-xl rounded-full blur-none flex z-50"
          ref={animationContainer}
        ></div>
      </BlurContainer>
      <div
        className={cn(gradientOverlayShared, "left-0 bg-gradient-to-r")}
      ></div>
      <div
        className={cn(gradientOverlayShared, "right-0 bg-gradient-to-l")}
      ></div>
      {productsAnimation}
    </Section>
  );
};

export default MarketExlporer;
