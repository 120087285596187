import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import cn from "classnames";

interface ProductCardProps {
  title?: string;
  subTitle?: string;
  image?: any;
  alignment?: string;
  section?: string;
  chartImage?: any;
  price?: string;
  className?: string;
}

const ProductCard: React.FC<ProductCardProps> = props => {
  const {
    title,
    subTitle,
    image = "../images/prism-page/sneakers.png",
    alignment = "left",
    section,
    chartImage,
    price,
    className
  } = props;
  const sharedBubbleClasses =
    "rounded-full my-2 bottom-6 h-2 sm:h-4 shadow-2xl";
  return (
    <div
      className={cn(
        "relative",
        { "pr-8": alignment === "left" },
        { "pl-8": alignment === "right" },
        className
      )}
    >
      <div className={cn("bg-white rounded-lg shadow-xl p-3 sm:p-4")}>
        {/* Pass relative path to component your passing to */}
        <GatsbyImage
          className="w-full mx-auto"
          alt=""
          image={image}
          width={200}
          height={200}
        />
        {section && (section == "market-explorer" || section == "power") ? (
          <div>
            <p aria-hidden className="text-sm sm:text-2xl font-bold text-left">
              {title}
            </p>
            {section == "market-explorer" ? (
              chartImage && chartImage
            ) : (
              <div>
                <div className="flex">
                  <div
                    className={cn("bg-gray-5 w-1/3", sharedBubbleClasses)}
                  ></div>
                  <div
                    className={cn("bg-gray-5 w-1/3 ml-2", sharedBubbleClasses)}
                  ></div>
                </div>
                <p className="text-sm sm:text-base mt-2">{price && price}</p>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className={cn("bg-gray-4 w-full", sharedBubbleClasses)}></div>
            <div className={cn("bg-gray-5 w-1/3", sharedBubbleClasses)}></div>
            <div
              className={cn(
                "bg-white font-semibold shadow-xl rounded-lg p-4 absolute bottom-[unset] min-w-[168px] -top-8 lg:top-[unset] lg:bottom-2",
                { "right-0": alignment === "left" },
                { "left-0": alignment === "right" }
              )}
            >
              <p aria-hidden className="text-sm text-gray-3 mb-1">
                {subTitle}
              </p>
              <p aria-hidden className="text-lg">
                {title}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
