import { graphql } from "gatsby";
import React from "react";
import Button, { ButtonSize } from "../components/Button";
import Ahead from "../components/Prism/Ahead";
import Competition from "../components/Prism/Competition";
import Herobanner from "../components/Prism/Herobanner";
import MarketExplorer from "../components/Prism/MarketExplorer";
import Power from "../components/Prism/Power";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { trackView, appendTrackingParams } from "../utils/analytics";
import { Name, Category } from "../utils/analytics/constants";
import { nav } from "../utils/navigation";

const Prism = ({ data }) => {
  const marketExlporerTitle = [
    {
      text: "Performance data",
      customClasses: "text-blue-1 text-right lg:w-[30%]"
    },
    {
      text: "+",
      customClasses: "text-white mx-8 w-[5%]"
    },
    {
      text: "market insights",
      customClasses: "text-indigo-1 text-left lg:w-[20%]"
    },
    {
      text: "=",
      customClasses: "text-white text-left mx-8 w-[5%]"
    },
    {
      text: "market awareness",
      customClasses: "text-blue-1 text-left lg:w-[30%] "
    }
  ];

  const aheadOptions = [
    {
      gradientTitle: ["Create and save ", "custom segments"],
      gradientPosition: 1,
      description:
        "Monitor ongoing and historical traffic to see trends over time, in your category, with raw data downloads to ensure consistent reporting out to stakeholders",
      image: data.segmentBuilder
    },
    {
      gradientTitle: ["Get the full picture, ", "faster than anyone else"],
      gradientPosition: 2,
      description:
        "Benchmark your category performance by sales volume, traffic, conversion rate, price, units sold, ratings, reviews and more",
      image: data.segmentBuilderTwo
    }
  ];

  return (
    // Pass custom dark mode prop
    <Layout theme="dark">
      <SEO
        title="Perpetua Prism | E-Commerce Market & Competitive Intelligence Tool"
        description="Perpetua Prism is a market & competitive intelligence tool that helps brands and agencies scale their eCommerce businesses. Track the performance of competing brands, discover new competitors and benchmark top performers."
        image={`https://perpetua.io${data?.openGraphImage?.publicURL}`}
      />
      <Herobanner />
      <MarketExplorer
        subTitle={"Market Explorer"}
        mainTitle={marketExlporerTitle}
        description={
          "Benchmark your category performance by sales volume, traffic, conversion rate, price, units sold, ratings, reviews and more. "
        }
      />
      <Ahead
        title={["Stay ", "one ", " step ", "ahead"]}
        options={aheadOptions}
      />
      <Competition
        gradientTitle={["Your competition has", "nowhere to hide"]}
        gradientTitlePosition={2}
        description={
          "Everything you’ve wanted to see about your competitors is one search away - pricing, rank, paid and organic keywords, ASIN assortment, and product details, all exposed in Prism."
        }
      />
      <Power
        gradientTitle={["The power of Perpetua,", "built right in"]}
        gradientTitlePosition={2}
        description={
          "Act on trends, make instant adjustments to your strategy and launch new advertising goals, all in a single dashboard."
        }
      />
      <Button
        onClick={() => {
          // Run track call then go somewhere
          trackView(Category.Prism + " LP", {
            category: Category.Prism,
            label: Name.Cta + " (Footer)"
          });
          nav(
            appendTrackingParams(
              "https://perpetuasurveys.typeform.com/to/OsZVcZNg/?loc=footer"
            )
          );
        }}
        className="mt-12 rounded-lg mb-52 mx-auto text-white overflow-hidden border-none group relative"
        size={ButtonSize.xlarge}
      >
        <span className="relative z-10 text-3xl md:text-7xl">
          Get early access
        </span>
        <div className="absolute z-0 w-[700px] h-[700px] ease-in duration-300 transition-all blur-sm radialGradientButton group-hover:rotate-180"></div>
      </Button>
    </Layout>
  );
};

export default Prism;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    segmentBuilder: file(
      relativePath: { eq: "prism-page/segment-builder-one.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    segmentBuilderTwo: file(
      relativePath: { eq: "prism-page/segment-builder-two.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    openGraphImage: file(
      relativePath: { eq: "prism-page/prism-open-graph.png" }
    ) {
      publicURL
    }
  }
`;
