import React, { createRef, useEffect } from "react";
import Header, { HeaderVariant } from "../../Header";
import TabletVectors from "../../../images/prism-page/tablet-vectors.svg";
import MobileVectors from "../../../images/prism-page/mobile-vectors.svg";
import DesktopVectors from "../../../images/prism-page/desktop-vectors.svg";
import BlurContainer from "../../../containers/BlurContainer";
import GradientBox from "../../GradientBox";
import cn from "classnames";
import Section from "../../../containers/Section";
import trends from "../../../animations/PrismPage/trends-animation.json";
import { runAnimation } from "../../../utils/animations";

interface CompetitionProps {
  gradientTitle: any;
  gradientTitlePosition: number;
  description: string;
}

const Competition: React.FC<CompetitionProps> = props => {
  const { gradientTitle, description } = props;

  const gradientBackground = {
    background:
      "conic-gradient(from 180deg at 50% 50%, #FA0164 0deg, #42A5F5 125.47deg, #8241F3 226.72deg, #FDBA74 360deg)"
  };

  const competitionFeatures = [
    "Uncover top organic & paid marketing strategies",
    "Deep dive into ASIN-level  sales and share of voice benchmarks",
    "Compare pricing and growth rates of your competitors",
    "Identify new category leaders and products"
  ];

  const animationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim = runAnimation(animationContainer.current, trends, false);
    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <Section className="py-0 md:py-22 mx-auto flex justify-center flex-col items-center lg:py-32">
      <div className="w-full mx-auto relative text-center">
        <div className="pb-8 justify-center">
          <Header
            variant={HeaderVariant.h2}
            color="purple"
            className="text-white text-center text-4xl md:text-6xl flex flex-col items-center"
          >
            {gradientTitle.map((text, i) => {
              return (
                <span
                  key={i}
                  className={
                    i === 1
                      ? "bg-gradient-to-r bg-clip-text from-indigo-1 to-blue-1 text-transparent md:text-6xl text-white md:block"
                      : "text-white"
                  }
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  {text}
                </span>
              );
            })}
          </Header>
        </div>
        <p
          data-sal="slide-up"
          data-sal-duration="500"
          data-sal-delay="600"
          data-sal-easing="ease"
          className="md:text-3xl mx-auto font-semibold max-w-2xl text-white mb-12"
        >
          {description && description}
        </p>
        <div
          className="w-full max-w-screen-sm rounded-full mx-auto my-12"
          ref={animationContainer}
        ></div>
        <div className="my-20 mx-auto relative">
          <MobileVectors
            className={cn(
              "absolute block mx-auto w-full min-w-[400px] left-1/2 -translate-x-1/2",
              "sm:hidden"
            )}
          />
          <TabletVectors
            className={cn("absolute hidden w-full", "sm:block", "lg:hidden")}
          />
          <DesktopVectors
            className={cn(
              "mx-auto absolute w-full hidden px-28",
              "lg:block",
              "xl:px-0"
            )}
          />
          <div
            className={cn(
              "grid grid-cols-1 pt-[260px]",
              "sm:grid-cols-2 sm:pt-[270px]",
              "md:pt-[40%]",
              "lg:grid-cols-4 lg:pt-[220px]",
              "xl:px-22"
            )}
          >
            {competitionFeatures &&
              competitionFeatures.map((feature, i) => {
                return (
                  <BlurContainer
                    key={i}
                    className={cn(
                      "mb-12 w-[180px] mx-auto odd:-translate-x-[70px] even:translate-x-[70px]",
                      "sm:odd:-translate-x-[0px] sm:even:translate-x-[0px] sm:w-[200px] sm:mb-18",
                      "md:mb-36",
                      "lg:mb-20"
                    )}
                  >
                    <GradientBox style={gradientBackground} title={feature} />
                  </BlurContainer>
                );
              })}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Competition;
